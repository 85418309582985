import React, {useState} from 'react';
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import {Physics, usePlane, useSphere, useBox } from "@react-three/cannon"
// import { Debug } from "@react-three/cannon"
// import Model from "./Model";
import { E, M, A, N, U, E2, L } from "./Model";
// import { OrbitControls} from "@react-three/drei";
// import { EffectComposer, SSAO, Bloom } from "@react-three/postprocessing"
import './App.css';

//
// import vertexShader from './vertexShader';
// import fragmentShader from './fragmentShader';



function InstancedCubes({ count = 240 }) {
    const { viewport } = useThree()
    count = 5 * viewport.width | 0
    const [ref] = useBox((index) => ({ mass: 100, position: [4 - Math.random() * 8, viewport.height, 0, 0], args: [2,2,2] }))
    return (
        <instancedMesh ref={ref} castShadow receiveShadow args={[null, null, count]}>
            <boxBufferGeometry args={[2, 2, 2]} />
            {/*<shaderMaterial*/}
            {/*    fragmentShader={fragmentShader}*/}
            {/*    vertexShader={vertexShader}*/}

            {/*/>*/}
            <meshPhysicalMaterial color={0xffffff} />
        </instancedMesh>
    )
}
function Borders() {
    const { viewport } = useThree()
    return (
        <>
            <Plane position={[0, -viewport.height / 2, 0]} rotation={[-Math.PI / 2, 0, 0]} />
            <Plane position={[-viewport.width / 2 - 1, 0, 0]} rotation={[0, Math.PI / 2, 0]} />
            <Plane position={[viewport.width / 2 + 1, 0, 0]} rotation={[0, -Math.PI / 2, 0]} />
            <Plane position={[0, 0, -1]} rotation={[0, 0, 0]} />
            <Plane position={[0, 0, 12]} rotation={[0, -Math.PI, 0]} />
        </>
    )
}

function Plane({ color, ...props }) {
    usePlane(() => ({ ...props }))
    return null
}

function Mouse() {
    const { viewport } = useThree()
    const [, api] = useSphere(() => ({ type: "Kinematic", args: [6] }))
    return useFrame((state) => api.position.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 7))
}




function App() {
    const [invertGravity, setInvertGravity] = useState(false)
  return (
      <Canvas shadows gl={{ stencil: false, antialias: false }} camera={{ position: [0, 0, 20], fov: 50, near: 1, far: 400 }}>
          <fog attach="fog" args={["grey", 25, 35]} />
          <color attach="background" args={["#1d1e21"]} />
          <ambientLight intensity={1.0} />
          <directionalLight position={[-10, -10, -5]} intensity={0.5} />
          <directionalLight
              castShadow
              intensity={4}
              position={[50, 50, 25]}
              shadow-mapSize={[256, 256]}
              shadow-camera-left={-10}
              shadow-camera-right={10}
              shadow-camera-top={10}
              shadow-camera-bottom={-10}
          />
          {/*<OrbitControls />*/}
          <Physics gravity={[0, invertGravity ? 5 : -20, 0]}>
              {/*<Debug color="black" scale={1.1}>*/}
              <group onPointerDown={() => {
                  setInvertGravity(!invertGravity)
              }}>
                  <E />
                  <M />
                  <A />
                  <N />
                  <U />
                  <E2 />
                  <L />
              </group>
              <group position={[0, 0, -10]} >
                  <Mouse />
                  <Borders />
                  <InstancedCubes />
              </group>
              {/*</Debug>*/}
          </Physics>
          {/*<EffectComposer>*/}
          {/*    <SSAO radius={0.4} intensity={1} luminanceInfluence={0.4} color="blue" />*/}
          {/*    <Bloom intensity={1.25} kernelSize={3} luminanceThreshold={0.5} luminanceSmoothing={0.0} />*/}
          {/*</EffectComposer>*/}
      </Canvas>

  );
}

export default App;
