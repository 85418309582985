
import React from "react";
import { useGLTF } from "@react-three/drei";
import { useBox } from "@react-three/cannon";


// import fragmentShader from "./fragmentShader";


export function E(props){
    const { nodes } = useGLTF("/Letters/E.glb");
    const [ref] =  useBox(() => ({ type: "Dynamic", args: [1.5, 0.8,2], rotation:[Math.PI/2,0,0], position:[-4.1,0,0], mass: 100}))
    return (
        <mesh ref={ref} scale={4}
              geometry={nodes.Text.geometry}
        ><meshPhysicalMaterial color={"green"} />
        </mesh>
    );
}

export function M(props){
    const { nodes } = useGLTF("/Letters/M.glb");
    const [ref] =  useBox(() => ({ type: "Dynamic", args: [2, 0.8,2], rotation:[Math.PI/2,0,0], position:[-2.1,0,0], mass: 100}))
    return (
        <mesh ref={ref} scale={4}
              geometry={nodes.Text.geometry}
        ><meshPhysicalMaterial color={"green"} />
        </mesh>
    );
}

export function A(props){
    const { nodes } = useGLTF("/Letters/A.glb");
    const [ref] =  useBox(() => ({ type: "Dynamic", args: [1.5, 0.8,2], rotation:[Math.PI/2,0,0], position:[-0.1,0,0], mass: 100}))
    return (
        <mesh ref={ref} scale={4}
              geometry={nodes.Text.geometry}
        ><meshPhysicalMaterial color={"green"} />
        </mesh>
    );
}

export function N(props){
    const { nodes } = useGLTF("/Letters/N.glb");
    const [ref] =  useBox(() => ({ type: "Dynamic", args: [1.5, 0.8,2], rotation:[Math.PI/2,0,0], position:[1.9,0,0], mass: 100}))
    return (
        <mesh ref={ref} scale={4}
              geometry={nodes.Text.geometry}
        ><meshPhysicalMaterial color={"green"} />
        </mesh>
    );
}

export function U(props){
    const { nodes } = useGLTF("/Letters/U.glb");
    const [ref] =  useBox(() => ({ type: "Dynamic", args: [1.5, 0.8,2], rotation:[Math.PI/2,0,0], position:[3.9,-0.05,0], mass: 100}))
    return (
        <mesh ref={ref} scale={4}
              geometry={nodes.Text.geometry}
        ><meshPhysicalMaterial color={"green"} />
        </mesh>
    );
}

export function E2(props){
    const { nodes } = useGLTF("/Letters/E.glb");
    const [ref] =  useBox(() => ({ type: "Dynamic", args: [1.5, 0.8,2], rotation:[Math.PI/2,0,0], position:[5.7,0,0], mass: 100}))
    return (
        <mesh ref={ref} scale={4}
              geometry={nodes.Text.geometry}
        ><meshPhysicalMaterial color={"green"} />
        </mesh>
    );
}

export function L(props){
    const { nodes } = useGLTF("/Letters/L.glb");
    const [ref] =  useBox(() => ({ type: "Dynamic", args: [1.5, 0.8,2], rotation:[Math.PI/2,0,0], position:[7.2,0,0], mass: 100}))
    return (
        <mesh ref={ref} scale={4}
              geometry={nodes.Text.geometry}
        ><meshPhysicalMaterial color={"green"} />
        </mesh>
    );
}

useGLTF.preload("/Letters/E.glb")
useGLTF.preload("/Letters/M.glb");
useGLTF.preload("/Letters/A.glb");
useGLTF.preload("/Letters/N.glb");
useGLTF.preload("/Letters/U.glb");
useGLTF.preload("/Letters/L.glb");


